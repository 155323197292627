import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://billhwangdefi.co'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/pool'
      }
    ]
  },
  {
    label: 'Insider Trading',
    icon: 'FarmIcon',
    href: 'https://billhwangdefi.co/farms'
  },
  {
    label: 'FOMO',
    icon: 'PoolIcon',
    href: 'https://billhwangdefi.co/nests'
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://billhwangdefi.co/lottery'
  // },
 

  {
    label: 'Casino',
    icon: 'PoolIcon',
    href: 'https://billhwangdefi.co/casino',
  },


  {
    label: 'BillVaults',
    icon: 'PoolIcon',
    href: 'https://vaults.billhwangdefi.co',
  },
  
  {
    label: 'IMO',
    icon: 'GooseIcon',
    href: 'https://billhwangdefi.co/imo',
  },


  {
    label: 'Audit by CertiK',
    icon: 'AuditIcon',
    href: 'https://www.certik.org/projects/billhwangfinance',
  },
  {
    label: 'Audit by Solidity',
    icon: 'AuditIcon',
    href: 'https://solidity.finance/audits/BillHwang/',
  },
 
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/BillHwang/',
      },
      {
        label:"Blog",
        href:"https://medium.com/@billhwangfinance"
      },
      {
        label:"Documentation",
        href:"https://billhwang-defi.gitbook.io/billhwang-defi/"
      }
    ],
  },
]

export default config
